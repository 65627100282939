import React, { useEffect, useState } from 'react';
import { Script } from 'gatsby';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any;
  }
}

const gtag: Gtag.Gtag = function () {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
};

export const contactStackConversion = () => {
  if (process.env.NODE_ENV !== 'development') {
    gtag('event', 'conversion', {
      send_to: `${process.env.GATSBY_AW}/s6FcCJPRwsgDEOPi8MED`,
    });
  }
};

export const contactFormConversion = () => {
  if (process.env.NODE_ENV !== 'development') {
    gtag('event', 'conversion', {
      send_to: `${process.env.GATSBY_AW}/lMQ8CN2GkcgDEOPi8MED`,
    });
  }
};

export const problemSolvedConversion = () => {
  if (process.env.NODE_ENV !== 'development') {
    gtag('event', 'conversion', {
      send_to: `${process.env.GATSBY_AW}/IqTlCI2gzMsDEOPi8MED`,
    });
  }
};

const GoogleTagManager: React.FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) {
      window.dataLayer = window.dataLayer || [];
      gtag('js', new Date());
      if (process.env.NODE_ENV === 'development') {
        gtag('config', `${process.env.GATSBY_GTAG}`, {
          debug_mode: true,
        });
      } else {
        gtag('config', `${process.env.GATSBY_GTAG}`);
      }
      gtag('config', `${process.env.GATSBY_AW}`);
    }
  }, [loaded]);

  return (
    <React.Fragment>
      <Script
        onLoad={() => setLoaded(true)}
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GTAG}`}
      />
    </React.Fragment>
  );
};

export default React.memo(GoogleTagManager);
