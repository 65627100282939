import React from 'react';
import { graphql } from 'gatsby';
import clsx from 'clsx';
import loadable from '@loadable/component';

import { LazyloadBackground, LazyloadBackgroundProps } from 'components/Image';
import { useMapbox } from './hooks';
import ExternalLink from 'components/Hyperlink/ExternalLink';

import './styles.scss';

export const mapSectionContactUsFragment = graphql`
  fragment mapSectionContactUs on ContentfulContactUs {
    mapReference {
      officeAddressLocality
      officeGoogleMapLink
      officePostcode
      officeStreetAddress
      placeholderImage: image {
        alt
        image {
          mediaQuery
          quality
          url
        }
      }
    }
  }
`;

export type MapSectionProps = {
  officeAddressLocality?: string | null;
  officeGoogleMapLink?: string | null;
  officePostcode?: string | null;
  officeStreetAddress?: string | null;
  placeholderImage?: Omit<LazyloadBackgroundProps, 'local'> | null;
};

const MapboxLoadable = loadable(() => import('components/MapSection/Mapbox'));

const MapSection: React.FunctionComponent<MapSectionProps> = ({
  officeAddressLocality,
  officeGoogleMapLink,
  officePostcode,
  officeStreetAddress,
  placeholderImage,
}) => {
  const { handleLoaded, handleScroll, load, loaded, ref, scroll } = useMapbox();

  const classNames = clsx('map', loaded && 'loaded');
  return (
    <div className={classNames}>
      <div>
        <div ref={ref}>
          {placeholderImage?.image && (
            <LazyloadBackground
              image={placeholderImage?.image}
              local={false}
              tag="span"
            />
          )}
          {load && (
            <MapboxLoadable
              onLoad={handleLoaded}
              onMouseDown={() => handleScroll(true)}
              onMouseOut={() => handleScroll(false)}
              scrollZoom={scroll}
            />
          )}
        </div>
      </div>
      <div>
        <div className="container">
          <p>
            {officeStreetAddress}, {officeAddressLocality}, {officePostcode}
          </p>
          {officeGoogleMapLink && (
            <ExternalLink
              href={officeGoogleMapLink}
              label="View on Google Maps"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(MapSection);
