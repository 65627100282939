import yup from 'modules/validation';

export type Model = {
  formCompany: string;
  formEmail: string;
  formEnquiryType: string;
  formName: string;
  formTelephone: string;
  formWebsiteAddress: string;
};

export const defaultValues: Model = {
  formCompany: '',
  formEmail: '',
  formEnquiryType: "I'd like to find out more about how you could help",
  formName: '',
  formTelephone: '',
  formWebsiteAddress: '',
};

export const schema = yup.object().shape({
  formCompany: yup.string(),
  formEmail: yup.string().email().required(),
  formEnquiryType: yup.string(),
  formName: yup.string().required(),
  formTelephone: yup.string().telephone(),
  formWebsiteAddress: yup.string(),
});
