import React from 'react';

import FormSectionContactUs from 'components/FormSection/ContactUs';
import Hero from 'components/Hero';
import Main from 'components/Main';
import MapSection from 'components/MapSection';

type TemplateProps = Queries.ContactUsQuery;

const Template: React.FunctionComponent<TemplateProps> = ({
  contentfulContactUs: props,
}) => {
  return (
    <Main>
      <Hero {...props?.heroReference} />
      <FormSectionContactUs {...props?.formSection} />
      <MapSection {...props?.mapReference} />
    </Main>
  );
};

export default React.memo(Template);
