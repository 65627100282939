import { useEffect, useRef, useState } from 'react';

export const useMapbox = () => {
  const [load, setLoad] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [scroll, setScroll] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleLoad = () => setLoad(true);
  const handleLoaded = () => setLoaded(true);
  const handleScroll = (boolean: boolean) => setScroll(boolean);

  useEffect(() => {
    const element = ref?.current;
    ['mousemove', 'touchstart'].forEach(event =>
      element?.addEventListener(event, handleLoad),
    );
    return () => {
      ['mousemove', 'touchstart'].forEach(event =>
        element?.removeEventListener(event, handleLoad),
      );
    };
  }, []);

  return {
    handleLoaded,
    handleScroll,
    load,
    loaded,
    ref,
    scroll,
  };
};
