import React from 'react';

import { contactFormConversion } from 'modules/scripts/marketing/GoogleTagManager';
import { Error, FormOrigin, Submitted, useForm } from 'modules/form';
import Form, { Model } from './Form';

import './styles.scss';

type ContactUsFormType = {
  description?: {
    description: string | null;
  } | null;
  submittedDescription?: {
    raw: string | null;
  } | null;
  submittedTitle?: string | null;
  title?: string | null;
};

const ContactUsForm: React.FunctionComponent<ContactUsFormType> = ({
  description,
  submittedDescription,
  submittedTitle,
  title,
}) => {
  const {
    errorRef,
    handleSubmit,
    isError,
    isSubmitted,
    isSubmitting,
    recaptcha,
    setIsSubmitting,
    submittedRef,
  } = useForm<Model>({
    callback: contactFormConversion,
    formOrigin: FormOrigin.CONTACT_US,
  });

  return isError ? (
    <Error
      className="contact-us-settled"
      forwardedRef={errorRef}
      message={isError}
    />
  ) : isSubmitted ? (
    <Submitted
      className="contact-us-settled"
      description={submittedDescription?.raw}
      forwardedRef={submittedRef}
      title={submittedTitle}
    />
  ) : (
    <Form
      description={description?.description}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      recaptcha={recaptcha}
      setIsSubmitting={setIsSubmitting}
      title={title}
    />
  );
};

export default React.memo(ContactUsForm);
