import React from 'react';
import { UseFormReset } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';

import { defaultValues, Model, schema } from './model';
import {
  InputAutocomplete,
  InputMode,
  InputType,
} from 'components/Inputs/Input';
import { selectOptions } from 'modules/form';
import Arrow from 'modules/theme/images/button/arrow.svg';
import Button, { ButtonColour, ButtonType } from 'components/Button';
import Form, { FormErrors, Recaptcha } from 'components/Form';
import QuestionSelect from 'components/Questions/QuestionSelect';
import QuestionText from 'components/Questions/QuestionText';

export type { Model } from './model';

type ContactUsFormType = {
  description?: string | null;
  handleSubmit: (values: Model, reset: UseFormReset<Model>) => void;
  isSubmitting: boolean;
  recaptcha: {
    handleOnError: () => void;
    handleOnExpire: () => void;
    forwardedRef: React.MutableRefObject<ReCAPTCHA | null>;
  };
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string | null;
};

const ContactUsForm: React.FunctionComponent<ContactUsFormType> = ({
  description,
  handleSubmit,
  isSubmitting,
  recaptcha,
  setIsSubmitting,
  title,
}) => {
  return (
    <section className="contact-us-form">
      <div className="container">
        {title && <h2>{title}</h2>}
        {description && <p>{description}</p>}
        <Form<Model>
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          recaptchaRef={recaptcha.forwardedRef}
          setIsApiSubmitting={setIsSubmitting}
          schema={schema}>
          {({ control, errors, isFormSubmitting, loadRecaptcha }) => (
            <React.Fragment>
              <div>
                <QuestionText
                  autoComplete={InputAutocomplete.NAME}
                  control={control}
                  label="Name"
                  name="formName"
                  readOnly={isFormSubmitting || isSubmitting}
                />
                <QuestionText
                  autoComplete={InputAutocomplete.EMAIL}
                  control={control}
                  inputMode={InputMode.EMAIL}
                  label="Email"
                  name="formEmail"
                  readOnly={isFormSubmitting || isSubmitting}
                  type={InputType.EMAIL}
                />
              </div>
              <div>
                <QuestionText
                  autoComplete={InputAutocomplete.ORGANIZATION}
                  control={control}
                  label="Company"
                  name="formCompany"
                  readOnly={isFormSubmitting || isSubmitting}
                />
                <QuestionText
                  autoComplete={InputAutocomplete.WEBSITE}
                  control={control}
                  inputMode={InputMode.URL}
                  label="Website"
                  name="formWebsiteAddress"
                  readOnly={isFormSubmitting || isSubmitting}
                />
              </div>
              <div>
                <QuestionText
                  autoComplete={InputAutocomplete.TELEPHONE}
                  control={control}
                  inputMode={InputMode.TEL}
                  label="Telephone"
                  readOnly={isFormSubmitting || isSubmitting}
                  name="formTelephone"
                />
                <QuestionSelect
                  control={control}
                  label="How Can We Help?"
                  name="formEnquiryType"
                  options={selectOptions}
                  readOnly={isFormSubmitting || isSubmitting}
                />
              </div>
              <div>
                <FormErrors errors={errors} />
                <div>
                  <Button
                    colour={ButtonColour.RED}
                    disabled={isFormSubmitting || isSubmitting}
                    label="Submit"
                    loading={isFormSubmitting || isSubmitting}
                    type={ButtonType.SUBMIT}>
                    <Arrow />
                  </Button>
                  <Recaptcha
                    load={loadRecaptcha}
                    ref={recaptcha.forwardedRef}
                    {...recaptcha}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        </Form>
      </div>
    </section>
  );
};

export default React.memo(ContactUsForm);
